import axios from "axios";
import classNames from "classnames";
import parse from "html-react-parser";
import {useContext, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import {Field, Form} from "react-final-form";
import ButterCMSContentContext from "../../context/butterCMSContent";
import {
  composeValidators,
  required,
  validateEmail,
} from "../../Shared/Form/validators";
import TextField from "../../Shared/Form/TextField";
import styles from "./ContactFormSection.module.scss";
import Link from "next/link";

export default function ContactFormSection({className, content}) {
  const {contact_form_section} = useContext(ButterCMSContentContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    email_placeholder,
    form_heading,
    form_submit_text,
    form_success_message,
    disclosure,
  } = contact_form_section || content || {};

  function handleSubmit(values) {
    return axios
      .post("/api/forms/newsletter-signup", {data: values})
      .then(() => {
        setShowSuccessMessage(true);
      });
  }

  if (showSuccessMessage) {
    return (
      <div
        className={classNames(
          className,
          styles.contactFormSection,
          "container"
        )}
      >
        <div className="row">
          <div className="col-12">
            <h3 className={styles.formSuccessMessage}>
              {form_success_message}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(className, styles.contactFormSection, "container")}
    >
      <div className="row">
        <div
          className={classNames(
            "col-12 col-md-6 col-lg-7 align-items-center",
            styles.textContent
          )}
        >
          <h3 className={styles.formHeading}>{form_heading}</h3>
          {disclosure ? (
            <div className={styles.disclosure}>{parse(disclosure)}</div>
          ) : (
            <div className={styles.privacy}>
              See our{" "}
              <Link href="/privacy" prefetch={false} legacyBehavior={true}>
                <a target="_blank">privacy policy</a>
              </Link>
              .
            </div>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-5">
          <Form initialValues={{EMAIL: ""}} onSubmit={handleSubmit}>
            {({handleSubmit, form, submitting}) => (
              <form
                onSubmit={(event) =>
                  handleSubmit(event).then(() => {
                    form.reset();
                  })
                }
                noValidate
              >
                <div className="d-flex align-items-start">
                  <Field
                    name="EMAIL"
                    validate={composeValidators(required, validateEmail)}
                  >
                    {(props) => (
                      <TextField
                        {...props}
                        wrapperClassName="flex-grow-1 me-2"
                        className={classNames(
                          styles.emailInput,
                          "ignoreGlobal"
                        )}
                        disabled={submitting}
                        placeholder={email_placeholder}
                        type="email"
                      />
                    )}
                  </Field>
                  <Button
                    className={styles.submitBtn}
                    disabled={submitting}
                    type="submit"
                    variant="secondary"
                  >
                    {form_submit_text}
                    {submitting && (
                      <Spinner
                        animation="border"
                        aria-hidden="true"
                        as="span"
                        className="ms-2"
                        role="status"
                        size="sm"
                      />
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
