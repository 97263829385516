import classNames from "classnames";
import styles from "./FormMessageBanner.module.scss";

export default function FormMessageBanner({message, onClose, show, alertType}) {
  return (
    <div
      className={classNames(styles.messageBanner, {
        [styles.visibleBanner]: show,
      })}
    >
      {alertType !== "error" && <BannerIcon />}
      <span>{message}</span>
      <button className={styles.bannerCloseBtn} onClick={onClose} type="button">
        <CloseIcon />
      </button>
    </div>
  );
}

function BannerIcon() {
  return (
    <svg
      className={styles.bannerIcon}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.26858V9.00458C16.999 10.7297 16.4404 12.4083 15.4075 13.79C14.3745 15.1718 12.9226 16.1826 11.2683 16.6717C9.61394 17.1608 7.8458 17.1021 6.22757 16.5042C4.60934 15.9064 3.22772 14.8015 2.28877 13.3542C1.34981 11.907 0.903833 10.195 1.01734 8.47363C1.13085 6.75223 1.79777 5.11364 2.91862 3.80224C4.03948 2.49083 5.55423 1.57688 7.23695 1.1967C8.91967 0.816507 10.6802 0.990449 12.256 1.69258"
        stroke="#FF8CDD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0003 2.60855L9.00034 10.6165L6.60034 8.21655"
        stroke="#FF8CDD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21849 1L1 8.21849"
        stroke="white"
        strokeWidth="1.20308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L8.21849 8.21849"
        stroke="white"
        strokeWidth="1.20308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
