export function formatCurrency(
  number,
  {
    ignoreShorthand = false,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
  } = {}
) {
  const formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
    style: "currency",
  });

  if (!ignoreShorthand && +number === 1000) {
    return "$1k";
  }

  return formatter.format(number);
}

export function formatDollars(amount) {
  return formatCurrency(amount, {
    ignoreShorthand: true,
  });
}
